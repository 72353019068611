<template>
  <section class="lk">
    <Header v-model="isOpen">
      <ul class="lk-menu">
        <li
          v-for="(link, i) in links"
          :key="i"
          :class="['lk-menu__item', { active: activeIndex === i }]"
          @click="onLink(i)"
        >
          <Icon :type="link.icon" />
          {{ link.title }}
        </li>

        <li
          class="lk-menu__item lk-menu__exit"
          @click="onLogout"
        >
          <Icon type="exit-to-app" />
          Выход
        </li>
      </ul>
    </Header>
    <div class="main">
      <router-view />
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("auth");
import Header from "./components/header/header.vue";

export default {
  name: "LocalCabinet",
  components: {
    Header,
  },
  data() {
    return {
      links: [
        {
          icon: "stars",
          title: "Мои предложения",
          to: "showcase-yellow",
        },
        {
          icon: "work-outline",
          title: "Мои услуги",
          to: "lkcontracts",
        },
        {
          icon: "account",
          title: "Профиль",
          to: "lkprofile",
        },
      ],
      isOpen: false,
      activeIndex: null,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    onLogout() {
      this.logout();
      this.$router.push("/login");
    },
    onLink(i) {
      this.activeIndex = i;
      this.isOpen = false;
      this.$router.push({ name: this.links[i].to });
    },
  },
  mounted() {
    let activePage = this.links.findIndex(
      (item) => item.to === this.$route.name
    );
    this.activeIndex = activePage;

    if (this.$route.path === "/lk") {
      this.$router.push({ name: "lkcontracts" });
    }
  },
};
</script>

<style lang="css">
.lk {
  padding: 40px 80px;
  display: flex;
  justify-content: space-between;
}

.lk .main {
  width: 100%;
  padding: 0 20px;
  max-width: 960px;
}

@media (max-width: 1024px) {
  .lk {
    padding: 20px 40px;
  }
}

@media (max-width: 991px) {
  .lk {
    flex-wrap: wrap;
    padding: 0 0 40px;
  }
}
</style>
